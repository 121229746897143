
import LineImg from '../assets/images/shadow-separator-wide-top.png';
import BusinessAreaImg from '../assets/images/businessarea.png';
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ImageSlider from "./Slider";
import { useHistory } from "react-router-dom";

function MainSection() {
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      once: true
    })
  }, []);

  if (width <= 768) {
    return (
      <>
        <div className="m-section">
          <div className="m-section__title">
            <div className="m-section__title__label">
              One of the most <br />Intimate company in Korea<br />For SMT Total Solution!<br /><br />
            </div>
            <div className="m-section__title__info">
              MYPP는 반도체, SMT 분야의 Total 솔루션을 공급하고 있는 업체로
              ERP Interface부터 MES, POP, WES, SECS-GEM Driver, System Integration 까지
              고객에 요청에 부응하는 자세로 각종 필요 프로그램을 SMT 전반에 걸쳐 제공하며
              전문화 된 기술력으로 최적화 된 솔루션을 제공합니다.
            </div>
          </div>
          <div className="m-seperator" />
          <div className="m-section__product">
            <br />
            <div className="title" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
              CORE PRODUCT
            </div>
            <br />
            <div className="m-grid">
              <div className="m-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
                <div className="m-grid__item__title">
                  M-SECSGEM
              </div>
                <div className="m-grid__item__text">
                  EQP/Host간 통신을 위한 Driver를 제공하여 Interface 시스템을 손쉽게 만들 수 있는 개발 툴 입니다.
              </div>
                <div className="m-grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-SECSGEM')}>
                    Learn More
                </div>
                </div>
              </div>
              <div className="m-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <div className="m-grid__item__title">
                  M-SUNDAY
              </div>
                <div className="m-grid__item__text">
                  SMT 전 라인 장비 인터페이스를 관리합니다.
              </div>
                <div className="m-grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-SUNDAY')}>
                    Learn More
                </div>
                </div>
              </div>
              <div className="m-grid__item" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <div className="m-grid__item__title">
                  M-MONDAY
              </div>
                <div className="m-grid__item__text">
                  개별 장비 각각의 MES를 수행합니다.
              </div>
                <div className="m-grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-MONDAY')}>
                    Learn More
                </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div><img className="section__seperator" src={LineImg} alt="" /></div>

          <div className="m-section__area">
            <div className="title">
              Business Area
            </div>
            <br/>
            <img className="m-section__area__img" src={BusinessAreaImg} alt="" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000" />
          </div>
        </div>
        <br/>
        <br/>
      </>
    )
  } else {
    return (
      <>
        <div className="section">
          <div className="section__title" data-aos="fade-in" data-aos-duration="200">
            <div className="section__title__info">
              <div className="section__title__label" data-aos="fade-right" data-aos-duration="1000">
                <f1 className="f1">
                  One of the most <br />Intimate company in Korea<br />For SMT Total Solution!<br /><br />
                </f1>
              </div>
              <div className="section__title__info" data-aos="fade-right" data-aos-delay="400" data-aos-duration="1000">
                <f2 className="f2">
                  M.Y.P.P 는 반도체, SMT 분야의 Total 솔루션을 공급하고 있는 업체로<br />
              ERP Interface부터 MES, POP, WES, SECS-GEM Driver, System Integration 까지<br />
              고객에 요청에 부응하는 자세로 각종 필요 프로그램을 SMT 전반에 걸쳐 제공하며<br />
              전문화 된 기술력으로 최적화 된 솔루션을 제공합니다.
            </f2>
              </div>
            </div>
          </div>
          <div className="section__product">
            <div className="title" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
              CORE PRODUCT
          </div>
            <div className="grid">
              <div className="grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
                <div className="grid__item__title">
                  M-SECSGEM
              </div>
                <div className="grid__item__text">
                  EQP/Host간 통신을 위한 Driver를 제공하여 Interface 시스템을 손쉽게 만들 수 있는 개발 툴 입니다.
              </div>
                <div className="grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-SECSGEM')}>
                    Learn More
                </div>
                </div>
              </div>
              <div className="grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <div className="grid__item__title">
                  M-SUNDAY
              </div>
                <div className="grid__item__text">
                  SMT 전 라인 장비 인터페이스를 관리합니다.
              </div>
                <div className="grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-SUNDAY')}>
                    Learn More
                </div>
                </div>
              </div>
              <div className="grid__item" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <div className="grid__item__title">
                  M-MONDAY
              </div>
                <div className="grid__item__text">
                  개별 장비 각각의 MES를 수행합니다.
              </div>
                <div className="grid__item__button">
                  <div className="button" onClick={() => history.push('/product/v/V-MONDAY')}>
                    Learn More
                </div>
                </div>
              </div>
            </div>
          </div>
          <div><img className="section__seperator" src={LineImg} alt="" /></div>
          <div className="section__area">
            <div className="title" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">
              Business Area
          </div>
            <img className="section__area__img" src={BusinessAreaImg} alt="" data-aos="fade-left" data-aos-delay="200" data-aos-duration="1000" />
          </div>
        </div>
        <div className="section__empty"></div>
        <div><img className="section__seperator" src={LineImg} alt="" /></div>

      </>
    );
  }
}
export default MainSection;