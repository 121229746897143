import XMES1 from '../../assets/product/V-Notifier.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VNOTIFIER() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-NOTIFIER</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">SMT 장비 에러 관리 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">장비에서 발생하는 Log 파일을 모니터링 하여 발생한 오류에 대한 설명을 알려줍니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          {/* <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} /> */}
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 오류 저장 </div>
                <div className="p-grid__item__right__text"> 장비에서 발생할 수 있는 오류를 파일로 저장하여 보관합니다. </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">오류 알림</div>
                <div className="p-grid__item__right__text">Model 별, Point 별, Data를 Table, Graph, PDF를 이용하여 사용자가 원하는 형태로 출력합니다장비에서 발생하는 Log 파일을 모니터링하여 오류가 발생했을 때 저장해 둔 오류내용과 비교해 알림을 발생시킵니다.</div>
              </div>
            </div>
          
          </div>
        </>
      </div>
    </>
  )
}

export default VNOTIFIER;