import XMES1 from '../../assets/product/xmes/1.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';
import LINK from '../../assets/brochure/brochure-wms.pdf';

function XMES() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-MES</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">제조실행 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">X-MES는 다양한 기술을 통합하기 위해 "Manufacturing 2025"에 중점을두고 있으며 똑똑한 공장을 설계하고 건설하기 위해 노력합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} /><br/>
          <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 정확한 생산 물류관리 </div>
                <div className="p-grid__item__right__text">
                  생산 현장의 물류 상황은 빠르게 변하고 있으며 간단하고 빠른 운영 방법으로 실시간 지원해야합니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  효과적인 생산 자원 관리
          </div>
                <div className="p-grid__item__right__text">
                  생산 자원에 대한 신속하고 정확한 이해를 통해 생산 자원의 사용을 보다 효과적으로 보장하고 향상시킬 수 있습니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  변화하는 시장에 대한 신속한 대응
          </div>
                <div className="p-grid__item__right__text">
                  더 나은 서비스 제공과 작업 오류를 줄이려면 사이트와 직접 통신하는 도구가 필요합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  현장에서의 실시간 모니터링
          </div>
                <div className="p-grid__item__right__text">
                  모든 상황과 의사결정 및 전달에 대해 실시간 모니터링합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-cpu-fill" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  전문 생산관리 및 자동화 시스템
          </div>

                <div className="p-grid__item__right__text">
                  생산 시스템은 여러 플랫폼, 클라이언트 및 장치와 통합되어야합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000">
                <i className="bi bi-eye" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  완벽한 품질 및 제품 추적 서비스
          </div>
                <div className="p-grid__item__right__text">
                  품질 문제의 근본 원인을 추적과 분석을위한 데이터를 제공하며, 손실을 줄이기 위해 신속히 복구하십시오.
          </div>
              </div>
            </div>
          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
          <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XMES;