import XMES1 from '../../assets/images/arch-label.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-label.png';
import LINK from '../../assets/brochure/brochure-label.pdf';

function XLABEL() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-LABEL</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">라벨 인쇄를위한 최상의 솔루션</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">라벨 디자인 및 관리 도구로서 모든 레이블을 통합 관리합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 저렴한 비용 </div>
                <div className="p-grid__item__right__text">
                이중 투자를 방지하기 위해 레이블 시스템은 여러 시스템을 도킹하여 중복구입하지 못하도록 하고 모든 시스템에서 반복합니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                자산 관리
          </div>
                <div className="p-grid__item__right__text">
                라벨 프린터의 수, 작업 상태를 알고 작업 분리를 수행해야합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                표준화
          </div>
                <div className="p-grid__item__right__text">
                여러 시스템이 보유한 레이블 규격을 일정하게 관리해야 할 필요가 있습니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                시각화
          </div>
                <div className="p-grid__item__right__text">
                코딩 된 디자인은 실제 사용 된 레이블과의 거리가 있으며 그림으로 공유하고 구별해야합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-cpu-fill" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                라벨 버전 관리
          </div>

                <div className="p-grid__item__right__text">
                라벨 변경 프로세스, 버전 및 사유를 추적하여 승인을 위해 게시해야합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000">
                <i className="bi bi-eye" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                낮은 전문성
          </div>
                <div className="p-grid__item__right__text">
                디자인하기 쉽고, 코드를 개발할 필요가없고 드래그 앤 드롭으로 구성 요소를 레이블링 할 수 있습니다. 전문가가 아닌 사람도 디자인 할 수 있습니다.
          </div>
              </div>
            </div>
          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
          <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XLABEL;