import "../App.scss";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import IMG from '../assets/images/hType4.png';

function Submenu() {
  return (
    <div className="main-nav__submenu">
      <div className="main-nav__submenu-item ">
        <Link to="/product/x/X-MES" className="sub-nav">X-SERIES</Link>
      </div>
      <div className="main-nav__submenu-item ">
        <Link to="/product/v/V-SECSGEM" className="sub-nav">V-SERIES</Link>
      </div>
    </div>
  )
}

const MenuItem = ({ title, link }) => (
    <div className="main-nav__item">
      <Link to={link} className="p1">{title}</Link>
    </div>
)

const MenuItem2 = ({ title, link }) => (
  <div className="main-nav__item2">
    <Link to={link} className="p1">{title}</Link>
  </div>
)

function Header() {
  const [product, SetProduct] = useState(false);
  const history = useHistory();

  const handleLeave = () => {
    SetProduct(false);
  };
  const handleHover = () => {
    SetProduct(true);
  };

  
  return (
    <>
      <div className="main-nav">
        <div className="main-nav__logo" onClick={() => history.push("/")}>
          <img className="main-nav__img" src={IMG} alt=""></img>
        </div>
        <MenuItem title="Company" link="/"></MenuItem>
        <MenuItem title="Product" link="/product/v/m-secsgem"></MenuItem>

        {/* <div onMouseLeave={handleLeave}>
          <div className="main-nav__item" onMouseEnter={handleHover}>
            <Link to="/product/x/X-MES" className="p1">Product</Link>
          </div>
          {product && Submenu()}
        </div> */}
        <MenuItem title="Experience" link="/experience"></MenuItem>
        <MenuItem2 title="Partner/Customer" link="/partner"></MenuItem2>
        <MenuItem title="History" link="/history"></MenuItem>
        <MenuItem title="Contact" link="/contact"></MenuItem>
      </div>
    </>
  );
}
export default Header;