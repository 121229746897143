import React, { useState, useEffect } from 'react';

function Sidebar() {
  const [isHideX, SetIsHideX] = useState(true);
  const [isHideV, SetIsHideV] = useState(true);
  const [isClick, SetIsClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  if (width <= 768) {
    return (
      <>
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>

        <div className={isClick === false ? "btn" : "btn click"} onClick={() => SetIsClick(!isClick)}>
          <i className={isClick === false ? "bi bi-list" : "bi bi-x"} style={{ fontSize: "30px", color: "#FFF", fontWeight: "bold" }}></i>
        </div>
        <nav id='style-3' className={isClick === false ? "sidebar" : "sidebar show"} style={{ overflowY: 'scroll' }}>
          <a href="/" className="sidebar-text">
            MYPP
          </a>
          <ul>
            <li><a href="/">Company</a></li>
            <li>
              <a href="#" onClick={() => SetIsHideX(!isHideX)}>Product</a>
              <ul style={isHideX === true ? { display: 'none' } : { display: 'inline' }}>
                <li><a href="/product/v/M-SECSGEM">M-SECSGEM</a></li>
                <li><a href="/product/v/M-SPC">M-SPC</a></li>
                <li><a href="/product/v/M-DIS">M-DIS</a></li>
                <li><a href="/product/v/M-SUNDAY">M-SUNDAY</a></li>
                <li><a href="/product/v/M-MONDAY">M-MONDAY</a></li>
                <li><a href="/product/v/M-NOTIFIER">M-NOTIFIER</a></li>
                <li><a href="/product/v/M-PLC">M-PLC</a></li>
                <li><a href="/product/v/M-PARSER">M-PARSER</a></li>
                <li><a href="/product/v/M-GERBER">M-GERBER</a></li>
                <li><a href="/product/v/M-AUTOMOUSE">M-AUTOMOUSE</a></li>
                <li><a href="/product/v/M-MONITORING">M-MONITORING</a></li>
                <li><a href="/product/v/M-ES">M-ES</a></li>
                <li><a href="/product/v/M-INTERLOCK">M-INTERLOCK</a></li>
                <li><a href="/product/v/M-CLEANER">M-CLEANER</a></li>
              </ul>
            </li>
            <li><a href="/experience">Experience</a></li>
            <li><a href="/partner">Partner/Customer</a></li>
            <li><a href="/history">History</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </>
    )
  } else {
    return (
      <>
      </>
    )
  }
}

export default Sidebar;