import XMES1 from '../../assets/product/V-Sunday_For_Viet.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VSUNDAY() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-SUNDAY</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">SMT Stand Alone Interface module</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">Fuji 장비 내 Nexim Host Interface 기능에서의 Event를 수집하며,
그 데이터를 바탕으로 공정의 세세한 파악이 가능해집니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 데이터 필터링 </div>
                <div className="p-grid__item__right__text"> 여러 종류의 Event 중 고객이 요구하는 정보가 들어가 있는 Event만 정해 받을 수 있습니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">데이터 백업</div>
                <div className="p-grid__item__right__text">피치못할 상황을 대비하여 공정 Data를 보내기 전 Local Database를 구성하고 저장하여 데이터의 손실을 막을 수 있습니다.</div>
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  )
}

export default VSUNDAY;