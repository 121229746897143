import XMES1 from '../../assets/images/5d2.jpg';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-5d.png';
import LINK from '../../assets/brochure/brochure-5d.pdf';


function XSD() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-SD</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">3D 시각화 모니터링</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">운영, 장비 관리, 원격 제어, IoT 제어를위한 3D 모델.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 실시간 모니터링 </div>
                <div className="p-grid__item__right__text">
                  3D 시각화와 실제 모니터링을 통해 모든 데이터를 애니메이션을 통해 표시 할 수 있습니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  예측 시뮬레이션
          </div>
                <div className="p-grid__item__right__text">
                  시뮬레이션 장치를 통해 정보를 축적하여 점진적 인텔리전스 예측 시뮬레이션을 수행할수 있고, 시뮬레이션 결과를 간단한 비디오로 저장할 수 있습니다
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  원격제어
          </div>
                <div className="p-grid__item__right__text">
                  실시간 3D 시각화를위한 자동 원격 제어 동작이 가능합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  라인 시스템 다이어그램
          </div>
                <div className="p-grid__item__right__text">
                  간편한 동작으로 원하는 정보를 간단하게 파악합니다.
          </div>
              </div>
            </div>
          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
  <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XSD;