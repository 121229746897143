import XMES1 from '../../assets/product/xmes/1.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-wms.png';
import LINK from '../../assets/brochure/brochure-wms.pdf';
function XWMS() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-WMS</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">창고 관리 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">지능형 창고 관리 시스템으로서 모든 동작을 관리합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 체계화, 정보화, 지능적 </div>
                <div className="p-grid__item__right__text">
                판단 및 의사 결정을 수행하는 창고 관리 시스템입니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                정확한 재고 추적 및 관리
          </div>
                <div className="p-grid__item__right__text">
                수동 입력 대신 바코드를 스캔하여 스캔하고 라벨로 정보를 확인합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                인바운드 관리
          </div>
                <div className="p-grid__item__right__text">
                인바운드 관리의 시각화와 모든 프로세스가 노드 진행 제어 및 추적을 처리합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                재고 관리
          </div>
                <div className="p-grid__item__right__text">
                조건에 따라 정확한 현재 재고를 조회하고, 재고 이동, 조절 등을 지원합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-cpu-fill" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                아웃 바운드 관리
          </div>

                <div className="p-grid__item__right__text">
                다양한 조건의 프로세스 노드를 추적합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000">
                <i className="bi bi-eye" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                시스템 확장성
          </div>
                <div className="p-grid__item__right__text">
                효율적이고 지능적인 기능을 달성하기 위해 ERP, MES 및 기타 시스템 도킹을 X-Mobile 시스템과 결합하여 간단하게 구현합니다.
          </div>
              </div>
            </div>
          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
          <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XWMS;