import XMES1 from '../../assets/images/scada2.PNG';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-scada.png';
import LINK from '../../assets/brochure/brochure-scada.pdf';


function XSCADA() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-SCADA</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">데이터 수집, 종합 모니터링</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">X-SCADA는 장치 도킹 및 장치 모니터링을 쉽고 빠르게 구현할 수있는 소프트웨어 도구입니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 유연한 시스템 확장성 </div>
                <div className="p-grid__item__right__text">
                구조를 쉽게 확장 할 수 있으며 사용자 정의 개발 인터페이스를 예약하여 쉽게 정의 할 수 있습니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                쉬운 장치 데이터 수집 설정
          </div>
                <div className="p-grid__item__right__text">
                획득 결과는 장치의 간단한 설정을 통해 대상 시스템으로 전송됩니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                실시간 데이터 모니터링
          </div>
                <div className="p-grid__item__right__text">
                신속한 의사 결정을 위한 시각적 정보를 제공합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                대규모 데이터 수집 처리를 위한 플랫폼
          </div>
                <div className="p-grid__item__right__text">
                실시간으로 대용량 데이터를 수집하고 처리하는 시스템 아키텍처 플랫폼에 의해 실현됩니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-cpu-fill" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                빅데이터를 기반으로 한 데이터 분석
          </div>

                <div className="p-grid__item__right__text">
                빅데이터 기반의 예측 시스템으로 효율성을 향상시킵니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="600" data-aos-duration="1000">
                <i className="bi bi-eye" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                효율적인 개발
          </div>
                <div className="p-grid__item__right__text">
                인터페이스 디자인 플랫폼 및 사용자 정의 가능한 인터페이스의 특성을 빠르고 효율적으로 모니터링함으로써 효율적인 개발을 가능하게 합니다.
          </div>
              </div>
            </div>
          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
  <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XSCADA;