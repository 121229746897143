import XMES1 from '../../assets/images/spc2.PNG';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-spc.png';
import LINK from '../../assets/brochure/brochure-spc.pdf';


function XSPC() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-SPC</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">통계 프로세스 제어 분석 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">제품의 상태를 효율적으로 관리하며 효과적으로 생산 환경을 제어합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 알람을 통한 업무 체계화 </div>
                <div className="p-grid__item__right__text">
                  설치 시스템에 따라 POPUP, EMAIL, SMS, OCAP 프로세스 수행 , 다른 시스템에 Alarm Message를 전송합니다.
          </div>
              </div>
            </div>

            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  시각화 / 투명성
          </div>
                <div className="p-grid__item__right__text">
                  제조 현장과 품질 상태를 시각화하고, 정보를 비교하여 즉각적인 차이를 분석합니다
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
                <i className="bi bi-speedometer2" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  유연성 / 확장성
          </div>
                <div className="p-grid__item__right__text">
                  시스템화 및 표준화의 원 스톱 시스템을 기반으로 생산 라인 확장 응답 시간 및 비용이 최소화 되며 장기적인 운영 비용 절감 효과를 얻을 수 있습니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="300" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="500" data-aos-duration="1000">
                <i className="bi bi-display" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  다중 데이터 분석 기능 지원
          </div>
                <div className="p-grid__item__right__text">
                  여러 조건에 따라 불필요한 정보는 제외하고 필요한 정보만 제공합니다.
          </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-cpu-fill" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">
                  오 코딩 방지
          </div>

                <div className="p-grid__item__right__text">
                  UI 제한을 통해 오 코딩을 방지합니다.
          </div>
              </div>
            </div>

          </div>
        </>
        <div className="p-border" />
        <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">BROCHURE</p>
        <a download href={LINK}>
  <img className="b-img" alt='' data-aos="flip-left" data-aos-delay="100" data-aos-duration="1000" src={XMES_IMG} />
        </a>
      </div>
    </>
  )
}

export default XSPC;