import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import XMES from './Product/XMES';
import XSCADA from './Product/XSCADA';
import XWMS from './Product/XWMS';
import XMONITORING from './Product/XMONITORING';
import XMOBILE from "./Product/XMOBILE";
import XSD from "./Product/XSD";
import XLABEL from "./Product/XLABEL";
import XSPC from "./Product/XSPC";

function ProductX({ match }) {
  const [pid, setPid] = useState("");

  useEffect(() => {
    setPid(match.params.pid);
    window.scrollTo(0, 0);
    AOS.init({
      easing: "ease-out-back",
      once: true
    })
  }, []);

  function MenuItem({ title }) {
    return (
      <div className="product-nav__item-x" onClick={() => setPid(title)}>
        <div className="p2">{title}</div>
      </div>
    )
  }

  const GetProduct = () => {
    if (pid == 'X-MES') {
      return (
        <XMES />
      );
    } else if (pid == 'X-SCADA') {
      return (
        <XSCADA />
      );
    } else if (pid == 'X-WMS') {
      return (
        <XWMS />
      );
    } else if (pid == 'X-MONITORING') {
      return (
        <XMONITORING />
      );
    } else if (pid == 'X-MOBILE') {
      return (
        <XMOBILE />
      );
    } else if (pid == 'X-SD') {
      return (
        <XSD />
      );
    } else if (pid == 'X-LABEL') {
      return (
        <XLABEL />
      );
    } else if (pid == 'X-SPC') {
      return (
        <XSPC />
      );
    } else {
      return (
        <XMES />
      );
    }
  }

  return (
    <>
      <div className="section">
        <div className="section__product-header" data-aos="fade-down" data-aos-delay="50" data-aos-duration="1000">
          <div className="product-nav">
            <MenuItem title="X-MES"></MenuItem>
            <MenuItem title="X-SCADA"></MenuItem>
            <MenuItem title="X-WMS"></MenuItem>
            <MenuItem title="X-MONITORING"></MenuItem>
            <MenuItem title="X-MOBILE"></MenuItem>
            <MenuItem title="X-SD"></MenuItem>
            <MenuItem title="X-LABEL"></MenuItem>
            <MenuItem title="X-SPC"></MenuItem>
            <MenuItem title=""></MenuItem>
            <MenuItem title=""></MenuItem>
            <MenuItem title=""></MenuItem>
            <MenuItem title=""></MenuItem>
            <MenuItem title=""></MenuItem>
          </div>
        </div>
        <div className="section__product-info">
          <div className="product-area">
            {GetProduct()}
          </div>
        </div>
      </div>
    </>
  )
}
export default ProductX;
