import XMES1 from '../../assets/product/V-SPC.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VSPC() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-SPC</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">SMT 장비 SPC Data 관리 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">V-SPC는 SPC Data를 사용자가 원하는 여러 가지 방법으로 출력해주는 것을 중점으로 두며,
체계적인 Data 관리를 지원합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> SPC Data 관리 </div>
                <div className="p-grid__item__right__text"> SPC Data를 Monitoring하여, Data를 정형화 및 DB에 저장하여 Data를 관리합니다. </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">Data 출력</div>
                <div className="p-grid__item__right__text">Model 별, Point 별, Data를 Table, Graph, PDF를 이용하여 사용자가 원하는 형태로 출력합니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">생산정보 관리</div>
                <div className="p-grid__item__right__text">사용자가 원하는 상한치. 하한치의 값을 벗어날 경우 알람을 통하여 사용자에게 알려줌으로서 생산정보 관리를 효과적으로 도와줍니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">MES와 통신</div>
                <div className="p-grid__item__right__text">MES와 통신하여 작업 일정, 작업 지시, 품질 관리, 작업 실적 집계 등 제반 활동을 지원합니다.</div>
              </div>
            </div>

          </div>
        </>
      </div>
    </>
  )
}

export default VSPC;