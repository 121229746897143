
import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import EXP from '../assets/images/customer/customer8.png';

function Experience() {
  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      once:true
    })
  }, []);
  return (
    <>
      <div className="section">
        <div className="section__experience">
          <div className="title" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500">
            Experience
          </div>
          <br />
          <p className='p6' data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">
            다양한 분야에서 수많은 현장경험을 토대로 축적된 기술과 노하우를 바탕으로<br />
            고객의 요구사항을 설계에서 구축, 서비스까지 토탈 솔루션을 제공해 드리고 있습니다.
          </p>
          <br /><br />
          <img className="experience__img" src={EXP} data-aos="fade-up" data-aos-delay="300" data-aos-duration="500"/>
        </div>
      </div>
    </>
  );
}
export default Experience;