import XMES1 from '../../assets/product/hynix_cleaner.png';
import XMES2 from '../../assets/product/Fluxcleaner.png';
import XMES3 from '../../assets/product/EssysFluxCleaner.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VCLEANER() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-CLEANER</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">Flux Cleaner 장비 관리 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">Flux Cleaner 장비를 제어하고 데이터를 수집하여 처리합니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
         
        </>
      </div>
    </>
  )
}

export default VCLEANER;