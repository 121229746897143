import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Location from '../assets/images/address.png';

function Contact() {
  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      once: true
    })
  }, []);
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="section">
        <div className="section__contact">
          <div className="title" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">
            Contact Us
          </div>
          <br /><br /><br />
          <div className="c-area">
            <div className="c-title" data-aos="fade-right" data-aos-delay="300" data-aos-duration="500">About Us</div>
            <div className="c-info" data-aos="fade-right" data-aos-delay="400" data-aos-duration="500">
              <p>M.Y.P.P 는 신세대 제조 지능화 IT 소프트웨어 회사로써, 기업 관리 소프트웨어의 연구 개발 및 세일즈를 포함한 'High-tech IT' 기업입니다.
                저희는 꾸준히 사업 분야를 넓히고 있습니다. 최초의 서비스와 기업의 전문화 정보시스템 제공 및 개발로부터 중소기업과 고객을 직접 상대로 한 맞춤형 개발,
                 특히 모바일 버전의 앱 개발과 설비 버전의 자동화 시스템 개발까지 참여하고 있습니다.</p>
            </div>
            {/* <div className="p-border" /> */}
            {/* <div className="c-title" data-aos="fade-right" data-aos-delay="300" data-aos-duration="500">SUPPORT By E-mail</div> */}
            {/* <div className="c-info" > */}
              {/* <p data-aos="fade-right" data-aos-delay="400" data-aos-duration="500">Please email us your questions regarding our products.</p> */}
              {/* <p data-aos="fade-right" data-aos-delay="500" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-envelope-fill"></i> E-amil : info@MYPP.co.kr</p> */}
              {/* <p data-aos="fade-right" data-aos-delay="600" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-envelope-fill"></i> E-amil : kimho@MYPP.co.kr</p> */}
            {/* </div> */}
            <div className="p-border" />
            <div className="c-title" data-aos="fade-right" data-aos-delay="400" data-aos-duration="500">SUPPORT By HOTLINE</div>
            <div className="c-info">
              <p data-aos="fade-right" data-aos-delay="500" data-aos-duration="500">Please call us if you need any support regarding our products.(Monday to Friday, 9am to 6pm GMT+9)</p>
              <p data-aos="fade-right" data-aos-delay="600" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-telephone-fill"></i> Tel : +82 31-524-9901</p>
              <p data-aos="fade-right" data-aos-delay="700" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-printer-fill"></i> Fax : +82 31-524-9902</p>
              {/* <p data-aos="fade-right" data-aos-delay="800" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-telephone-fill"></i> Mobile : 010-9132-0033</p> */}
            </div>
            <div className="p-border" />
            <div className="c-title" data-aos="fade-right" data-aos-delay="500" data-aos-duration="500">Location</div>
            <div className="c-info">
              <p data-aos="fade-right" data-aos-delay="600" data-aos-duration="500">&nbsp;&nbsp;<i className="bi bi-geo-alt-fill"></i> address : 경기도 성남시 중원구 제일로 43 2층 204호</p>
            </div>
          </div>
          <br/>
          {/* <img className="contact-img" data-aos="fade-up" data-aos-delay="600" data-aos-duration="800" src={Location} alt="" /> */}
        </div>
      </div>
    </>
  );
}
export default Contact;