import XMES1 from '../../assets/product/V-AutoMouse.png';

function VAUTOMOUSE() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-AUTOMOUSE</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">오토마우스 프로그램</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">지정된 포인트를 순서에 맞게 반복하여 자동 클릭해 주는 프로그램 입니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          {/* <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} /> */}
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>

        </>
      </div>
    </>
  )
}

export default VAUTOMOUSE;