
import React, { useEffect, useState } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/autoplay/package.json";
import SwiperCore, { Autoplay } from "swiper";

function History() {
  const [isHide24, SetIsHide24] = useState(true);
  SwiperCore.use([Autoplay]);
  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      once: true
    })
  }, []);

  
  return (
    <>
      
      <div className="section">
        <div className="section__history">
          <div className="title" data-aos="fade-right" data-aos-delay="100" data-aos-duration="500">
            History
          </div>
          <div className="history-empty"></div>
          <div className="section__history__row">
            <div className="history-row" style={isHide24 === true ? { maxHeight: '600px' } : { maxHeight: '2000px' }}>
              <div className="history-years" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">2024<span className="small-number">(6)</span></div>
              <div className="history-items">
                <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">HL 클레무브 인도 Fuji Mounter Interface 개발
                </div><div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 ICT Interface 개발
                </div><div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 AUTOPIA Interface 개발
                </div><div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 Laser Cutting Machine AT Driver 도입
                </div><div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 Active Alignment AT Driver 도입
                </div><div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 Final Test AT Driver 도입
                </div>
              </div>
            </div>
            <div className="history-row-center" style={isHide24 === true ? { maxHeight: '600px' } : { maxHeight: '2000px' }}>
              <div className="history-years" data-aos="fade-right" data-aos-duration="500">Before 2023 <span className="small-number">(72)</span></div>
              <div className="history-items">
                <Swiper
                  spaceBetween={30}
                  autoplay={{delay: 0, disableOnInteraction: false, pauseOnMouseEnter: true}}
                  speed={4000}
                  loop={true}
                  allowTouchMove={false}
                  loopAdditionalSlides={1}
                  slidesPerView={2}
                > 
                  <SwiperSlide> 
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">사명 변경 ㈜M.Y.P.P 설립</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">LGIT ATS, Camolot, Final Oven SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대덕전자 S/P, 세정기, 솔더스토리지 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">하이닉스 이천 S/P, 라우터, 세정기 SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">와이솔 Jmtech Oven SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">KCC Rhem Reflow SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">KCC 코이닝 장비 SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파워로직스 2공장 SAKI AOI Interface</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파워로직스 2공장 노드슨 SELECTIVE EQ Interface</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Partron Screw Tightening Machine M-PLC 개발</div>
                  </SwiperSlide>
                  
                  <SwiperSlide>
                    
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Amkor MPM 부자재 Control Program 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">하이닉스(이천) 세정기 Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Powerlogic 야마하 마운터 Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Powerlogic 사키 AOI Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">하이닉스(청주) 세정기 Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 비젼 검사기 소프트웨어 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 연태 후지마운터 Upgarde 및 라인 확장 프로젝트 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파워로직스 후공정 비젼 검사기 소프트웨어 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">노드슨 셀렉티브 장비 MES Interface</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대덕전자 S/P, 세정기 MES Interface</div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">만도헬라 Front Touch Screen Application 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">만도헬라 후지마운터, AOI MES Interface 프로그램 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">만도헬라 MSL 자재 관리 프로그램 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">AMKOR SQUEEGEE, METALMASK, BLAOCK 관리 프로그램 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">STATS ChipPAC Fuji Mounter M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Partron Mounter M-SECS-DRIMER 설치(연태)</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Partron Mounter M-SECS-DRIMER 설치(베트남)</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Nextech MES Upgrade 계약</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대덕전자 S/P M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">ESSYS Flux Cleaner M-PLC 개발</div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">SK hynix Router M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">SK hynix S/P M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">SK hynix Fuji Mounter M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">LSEMP Dispenser Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">X-SCADA REBOOT Project 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">삼성디스플레이 세정기 Interface 진행(인도법인)</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">ATS LM Serise Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Nextech MES 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">EMSYS SPC DATA Chart Program 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Kyungshin AOI Interface 진행</div>
                  </SwiperSlide>
                    
                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">China-Korea SMT AOI Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Haeng Sung AOI Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Seoul Semiconductor AOI Interface 진행</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">ATS M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">PowerLogics Mounter Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">M-SUNDAY 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">YURA corp X-ray EQ Interface</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">PowerLogics EQ Interface</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">X-SCADA 확장(전 SMT EQ 적용)</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">China HDC X-SCADA 도입</div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">MODBUS 등 PLC Interface 확장</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Top Solution Interface Agent</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">M-SECS-DRIMER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Intercem Interface S/W Agent</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Japan Fuji Mounter M-SECS-DRIMER 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">Fuji M-SECS-DRIMER MES 자동화 컨설팅</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">하이닉스 청주 세정기 SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파워로직스 베트남 1공장 후지마운터 Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대덕전자 헤드스토리지 SECSGEM Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">행성디지털(천안) SAKI AOI Interface</div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">HL클레무브(멕시코) Fuji Mounter Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대덕전자 헤더스토리지 Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">LGIT 탑솔루션 프린터 SECSGEM Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">LGIT DIB SR 가건조기 SECSGEM Interface 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 탑솔루션 Dispenser AT Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">베트남 파트론 HIT Printer ATDRIVER 개발</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 리트젠 UV Curing Machine AT Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 헬라 Reflow AT Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 모션시스템 Screww AT Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 한화정밀기계 Mounter AT Driver 도입</div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">파트론 베트남 아폴로세이코 Selective AT Driver 도입</div>
                    <div className="history-item" data-aos="fade-right" data-aos-delay="200" data-aos-duration="500">대하전선 베트남 법인 하네스 Interface 개발</div>
                  </SwiperSlide>
                </Swiper> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


export default History;