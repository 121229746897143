import XMES1 from '../../assets/product/V-Monday.png';
import XMES2 from '../../assets/product/xmes/2.png';
import XMES3 from '../../assets/product/xmes/3.png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VMONDAY() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-MONDAY</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">SMT 치공구 관리 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">V-Monday는 생산 관리를 효율적으로 하기 위해 사용자 편의성에 중점을 두고 있으며
스마트 공장을 설계하고 건설하기 위해 노력합니다.
</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 자재 관리 </div>
                <div className="p-grid__item__right__text"> 사용할 자재를 등록, 변경, 삭제 등의 기본적인 관리를 지원합니다. </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">실시간 자재 현황 제공</div>
                <div className="p-grid__item__right__text">실시간 자재 소모현황을 사용자가 보기 쉬운 형태로 UI를 통해서 출력합니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">장비 자동 제어</div>
                <div className="p-grid__item__right__text">사용자가 설정한 한계치만큼 자재를 모두 사용할 경우, 알람을 출력하고 장비를 자동으로 제어합니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">MES와 통신</div>
                <div className="p-grid__item__right__text">MES와 통신하여 작업 일정, 작업 지시, 품질 관리, 작업 실적 집계 등 제반 활동을 지원합니다.</div>
              </div>
            </div>

          </div>
        </>
      </div>
    </>
  )
}

export default VMONDAY;