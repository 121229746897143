import XMES1 from '../../assets/product/V-DIS(main).png';
import XMES2 from '../../assets/product/V-DIS(Summary).png';
import XMES3 from '../../assets/product/V-DIS(Graph).png';
import XMES_IMG from '../../assets/brochure/brochure-thumb-mes.png';

function VDIS() {
  return (
    <>
      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"></link>
      <div className="top-area">
        <div className="left-area">
          <div className="p-empty" />
          <>
            <br />
            <div className="p3" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">M-DIS</div>
            <div className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">SMT 장비 모니터링 시스템</div>
          </>
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <div className="p-empty" />
          <>
            <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">PRODUCT INFO</p>
            <p className="p6" data-aos="fade-right" data-aos-delay="200" data-aos-duration="1000">V-DIS는 실시간 생산 정보와 장비의 상태를 관리하며,
전체적인 생산 일정관리의 효율성을 높입니다.</p>
          </>

        </div>
        <div className="right-area">
          <img className="p-img" src={XMES1} />
          <img className="p-img-mini" src={XMES2} />
          <img className="p-img-mini" src={XMES3} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="p-border" />
        <>
          <p className="p4" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1000">CORE SERVICES</p>
          <br />
          <div className="p-grid">
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="0" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
                <i className="bi bi-calendar4-week" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title"> 실시간 생산정보 제공 </div>
                <div className="p-grid__item__right__text"> 생산 진행상황을 UI를 통하여 실시간으로 보여주고, 생산정보를 기록 및 저장합니다 </div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">장비관리</div>
                <div className="p-grid__item__right__text">장비의 가동시간 및 가동율을 계산하여 관리합니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">데이터 제공</div>
                <div className="p-grid__item__right__text">데이터들을 정형화 및 계산하여 사용자에게 보여줍니다.</div>
              </div>
            </div>
            <div className="p-grid__item" data-aos="flip-left" data-aos-delay="200" data-aos-duration="1000">
              <div className="p-grid__item__left" data-aos="flip-left" data-aos-delay="400" data-aos-duration="1000">
                <i className="bi bi-clipboard-data" style={{ fontSize: "45px", margin: "10px" }}></i>
              </div>
              <div className="p-grid__item__right">
                <div className="p-grid__item__right__title">MES와 통신</div>
                <div className="p-grid__item__right__text">MES와 통신하여 작업 일정, 작업 지시, 품질 관리, 작업 실적 집계 등 제반 활동을 지원합니다.</div>
              </div>
            </div>

          </div>
        </>
      </div>
    </>
  )
}

export default VDIS;