import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import Top from '../assets/partner/p02.png';
import Fuji from '../assets/partner/p03.png';
import Itw from '../assets/partner/p04.png';
import dibt from '../assets/partner/DIB.png';
import visiontechkorea from '../assets/partner/vziontek.png';
import humantek from '../assets/partner/humantek.png';
import sakicorp from '../assets/partner/saki.jfif';
import powerlogics from '../assets/partner/powerlogics.gif';
import daeduck from '../assets/partner/daeduck.png';
import wisol from '../assets/partner/wisol.png';
import skhynix from '../assets/partner/hynix.png';
import lginnotek from '../assets/partner/LG.png';
import bcakorea from '../assets/partner/bca.png';
import amkor from '../assets/partner/amkor.png';
import lsevkorea from '../assets/partner/daeha.gif';
import kcg from '../assets/partner/kc.jpg';
import essys from '../assets/partner/ESSYS.png';
import jcetglobal from '../assets/partner/jcet.png';
import hlklemove from '../assets/partner/hl.jpg';
import empkorea from '../assets/partner/emp.png';
import msys from '../assets/partner/msys.png';
import intercem from '../assets/partner/intercem.png';
import wooik from '../assets/partner/wooik.png';
import partron from '../assets/partner/partron.png';
import comet from '../assets/partner/comet.png';
import yamaha from '../assets/partner/yamaha.jpg';

import hmt from '../assets/partner/HMT.png';
import iljin from '../assets/partner/ILJIN.png';
import apollo from '../assets/partner/APollo.png';
import rehm from '../assets/partner/rehm-logo.png';
import jmtech from '../assets/partner/JM_TECH_Logo.png';


function Partner() {
  useEffect(() => {
    AOS.init({ easing: "ease-out-back" })
  }, []);
  return (
    <> < div className="section" > <div className="section__experience">
      <div
        className="title"
        data-aos="fade-right"
        data-aos-delay="200"
        data-aos-duration="500">
        Partner / Customer
      </div>
      <br /><br /><br />
      <div className="c-grid">

        <a
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="100"
          data-aos-duration="500"
          href="https://www.topsolutions.co.kr/"
          target="_blank">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={Top} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">Top Solution</p>
            </div>
          </div>
        </a>

        <a
          href="http://www.fujiamerica.com/evolution.html"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="200"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={Fuji} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">FUJI</p>
            </div>
          </div>
        </a>

        <a
          href="http://www.itw.com"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={Itw} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">ITW</p>
            </div>
          </div>
        </a>

        <a
          href="http://www.dibt.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={dibt} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">DIB</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.visiontechkorea.com/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={visiontechkorea} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">VISIONTECH</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.humantek.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={humantek} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">HUMANTEK</p>
            </div>
          </div>
        </a>
        <a
          href="https://m.blog.naver.com/sakicorp/222096391470"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={sakicorp} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">SAKICORP</p>
            </div>
          </div>
        </a>
        <a
          href="http://powerlogics.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={powerlogics} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">POWERLOGICS</p>
            </div>
          </div>
        </a>
        <a
          href="http://nyskor.com//"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={yamaha} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">YAMAHA</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.daeduck.com/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={daeduck} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">DAEDUCK</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.wisol.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={wisol} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">WISOL</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.skhynix.com/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={skhynix} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">SKHYNIX</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.lginnotek.com/main/main.do"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={lginnotek} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">LGINNOTEK</p>
            </div>
          </div>
        </a>
        <a
          href="http://bcakorea.co.kr/default/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={bcakorea} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">BCAKOREA</p>
            </div>
          </div>
        </a>
        <a
          href="https://amkor.com/kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={amkor} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">AMKOR</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.daehacable.co.kr/kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={lsevkorea} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">DAEHA CABLE</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.kcg.co.kr/kor/main.asp"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={kcg} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">KCG</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.essys.co.kr/ko/index.html"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={essys} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">ESSYS</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.jcetglobal.com/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={jcetglobal} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">JCETGLOBAL</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.hlklemove.com/main.do"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={hlklemove} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">HLKLEMOVE</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.empkorea.net/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={empkorea} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">EMPKOREA</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.msys21.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={msys} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">MSYS</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.intercem.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={intercem} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">INTERCEM</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.wooik.com/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={wooik} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">WOOIK</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.partron.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={partron} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">PARTRON</p>
            </div>
          </div>
        </a>
        <a
          href="https://comet.tech/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={comet} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">COMET</p>
            </div>
          </div>
        </a>

        <a
          href="http://www.hmtkorea.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={hmt} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">HMT</p>
            </div>
          </div>
        </a>
        <a
          href="http://iljinfa.co.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={iljin} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">ILJIN</p>
            </div>
          </div>
        </a>
        <a
          href="http://www.apolloseiko.co.kr/2013/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={apollo} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">APOLLOSEIKO</p>
            </div>
          </div>
        </a>
        <a
          href="https://www.rehm-group.com/en/index.html"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={rehm} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">REHM</p>
            </div>
          </div>
        </a>
        <a
          href="https://jmtech.or.kr/"
          target="_blank"
          className="c-grid__item"
          data-aos="flip-left"
          data-aos-delay="300"
          data-aos-duration="500">
          <div className="c-grid__item__area">
            <div className="c-grid__item__area__top">
              <img className="c-item-img" src={jmtech} alt="" />
            </div>
            <div className="c-grid__item__area__bottom">
              <p className="c-title">JMTECH</p>
            </div>
          </div>
        </a>
      </div>
    </div>
    </div>
    </>
  );
}
export default Partner;